import React from 'react'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { TestimonialCardExample } from '../../examples/html/TestimonialCardExample'

const Page = () => (
  <Content
    heading="TestimonialCard"
    subHeading="Verkkopalvelu"
  >
    <Playground
      format="html"
      example={TestimonialCardExample}
      isDark
    />
  </Content>
)

export default Page
