import React from 'react'
import styled from 'styled-components'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import placeholder2Image from '../../assets/placeholder-2.jpg'

const BubbleImageWrapper = styled.div`
  display: flex;
  justify-content: center;

  & > * {
    margin-right: 1rem;
    max-width: 100px;
  }
`

const SVG = {
  1: `
    <image
      height="100%"
      preserveAspectRatio="xMidYMid slice"
      style="clip-path: url('#bubble-image-clip-1')"
      width="100%"
      xlink:href="${placeholder2Image}"
    />
    <defs>
      <clipPath
        clipPathUnits="objectBoundingBox"
        id="bubble-image-clip-1"
        transform="scale(0.005617977528 0.004901960784)"
      >
        <path d="M125.31,194.51a4.73,4.73,0,0,1-.59,1.9c-1.51,2.66-6.25,3.81-10.66,3.55-28.19-1.64-72.52-20.42-98.58-60.88C-4.64,107.85-6,70.23,16.4,38.06a88.74,88.74,0,1,1,106,133,6.73,6.73,0,0,0-4.15,7.11c1.11,6,5.91,12.64,6.18,13.13a4.8,4.8,0,0,1,.88,2.46A3.38,3.38,0,0,1,125.31,194.51Z" />
      </clipPath>
    </defs>
  `,
  2: `
    <image
      height="100%"
      preserveAspectRatio="xMidYMid slice"
      style="clip-path: url('#bubble-image-clip-2')"
      width="100%"
      xlink:href="${placeholder2Image}"
    />
    <defs>
      <clipPath
        clipPathUnits="objectBoundingBox"
        id="bubble-image-clip-2"
        transform="scale(0.005617977528 0.004901960784)"
      >
        <path d="M140.2,15.89A89.09,89.09,0,0,0,16.43,38C-6,70.16-4.65,107.76,15.51,139c24.56,35,51.26,34.15,57.51,40.32,7.64,7.53,2.18,21.88,10,24.41s26.11-11.22,36.09-18.26c9-6.33,26.84-21.52,43.32-46.15C189.67,98.56,180.51,43.85,140.2,15.89Z" />
      </clipPath>
    </defs>
  `,
  3: `
    <image
      height="100%"
      preserveAspectRatio="xMidYMid slice"
      style="clip-path: url('#bubble-image-clip-3')"
      width="100%"
      xlink:href="${placeholder2Image}"
    />
    <defs>
      <clipPath
        clipPathUnits="objectBoundingBox"
        id="bubble-image-clip-3"
        transform="scale(0.005617977528 0.004901960784)"
      >
        <path d="M46,193a4.85,4.85,0,0,1,1-2.42c.3-.48,5.52-6.91,7-12.82a6.78,6.78,0,0,0-3.68-7.43,88.14,88.14,0,0,1-37.56-34.43C-12.54,93.56.91,38.5,42.86,12.92A88.29,88.29,0,0,1,164.71,43.25c20.27,33.85,16.51,71.66-5.57,101.81-28.6,39.07-74,55.1-102.29,54.94-4.42,0-9.07-1.5-10.4-4.27a4.71,4.71,0,0,1-.48-1.95A3.49,3.49,0,0,1,46,193Z" />
      </clipPath>
    </defs>
`
} as const

interface BubbleImageProps {
  className?: string
  variant?: 1 | 2 | 3
}

export const BubbleImage = ({ className, variant = 1 }: BubbleImageProps) => (
  <div className={`bubble-image${className ? ` ${className}` : ''}`}>
    <svg
      aria-hidden="true"
      className="bubble-image-svg"
      viewBox="0 0 178 204"
      dangerouslySetInnerHTML={{
        __html: SVG[variant]
      }}
    />
  </div>
)

const Page = () => (
  <Content
    heading="BubbleImage"
    subHeading="Verkkopalvelu"
  >
    <Playground
      format="html"
      WrapperComponent={BubbleImageWrapper}
    >
      <>
        <BubbleImage variant={1} />
        <BubbleImage variant={2} />
        <BubbleImage variant={3} />
      </>
    </Playground>
  </Content>
)

export default Page
