import React from 'react'

import { BubbleImage } from '../../pages/verkkopalvelu/bubbleimage'
import { LinkMock } from '../../components/LinkMock'

import ButtonHTMLExample from './ButtonHTMLExample'

const TestimonialCardExample = ({
  author = 'Petri Karjalainen',
  bubbleVariant = 1,
  content = 'Keksijä, yrittäjä',
  buttonLabel = 'Lue haastattelu'
}: {
  author: string
  bubbleVariant: 1 | 2 | 3
  content: string
  buttonLabel: string
}) => (
  <article className="testimonial-card">
    <BubbleImage
      className="testimonial-card-image"
      variant={bubbleVariant}
    />
    <h3 className="h5 mb-0 text-center">{author}</h3>
    <p className="testimonial-card-heading text-center h3">{content}</p>
    <ButtonHTMLExample
      as={LinkMock}
      color="secondary"
    >
      {buttonLabel}
    </ButtonHTMLExample>
  </article>
)

export const TestimonialCardRowExample = () => (
  <div className="row">
    <div className="offset-lg-1 col-lg-10">
      <div className="row">
        {(
          [
            {
              author: 'Petri Karjalainen',
              bubbleVariant: 1,
              content: 'Keksijä, yrittäjä',
              buttonLabel: 'Lue haastattelu'
            },
            {
              author: 'Timo Rahikainen',
              bubbleVariant: 2,
              content: 'Johtava puheterapeutti',
              buttonLabel: 'Lue haastattelu'
            },
            {
              author: 'Ulla Meikäläinen',
              bubbleVariant: 3,
              content: 'Bloggaaja',
              buttonLabel: 'Lue uratarina'
            }
          ] as const
        ).map(item => (
          <div
            className="col-sm-12 col-md-4"
            key={item.author}
          >
            <div className="mb-layout-lg mb-sm-layout-sm">
              <TestimonialCardExample
                author={item.author}
                bubbleVariant={item.bubbleVariant}
                content={item.content}
                buttonLabel={item.buttonLabel}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)

export { TestimonialCardExample }
